import { AddressObject, ResponseAddressObject } from "interfaces/DTO";
import useRequest from "./useRequest";
import { baseAPI, convertNetworkType, isTestnet, mempoolAPI } from "utils/configs";
import { parseCustomTokenValue } from "utils/convert";
import { getValidNetwork } from "./useContract";

const useBitcoin = () => {
    const request = useRequest();

    const getLockersByType = async (network: any, amountIn: any, type: any) => {
        const res: any = await request.get(
            `${baseAPI}/teleswap/select-locker/${convertNetworkType[getValidNetwork(network)]}?amount=${amountIn}&type=${type}`,
        );

        const lockers = res?.data;

        const lockerLockingScript = lockers?.preferred?.lockingScript;
        const lockerTargetAddressPreferred = lockers?.preferred?.targetAddress;
        return lockers;
        //TODO
        // try {
        //     const lockerTargetAddress = await lockerContract?.getLockerTargetAddress(lockerLockingScript);
        //     if (lockerTargetAddressPreferred == lockerTargetAddress) {
        //         return lockers;
        //     } else {
        //         throw new Error("!!Warning!!,Locker information is not correct");
        //     }
        // } catch (e) {
        //     throw new Error("!!Warning!!,Locker information is not correct");
        // }
    };

    const getAddressObjectByAddress = async (address: string): Promise<AddressObject> => {
        const responseAddressObject: ResponseAddressObject = await request.get<ResponseAddressObject>(
            `${baseAPI}/utils/bitcoin/address/${address}?testnet=${isTestnet}`,
        );
        return responseAddressObject?.data;
    };

    const sendBitcoinTx = async (txHex: any): Promise<any> => {
        const responseAddressObject: ResponseAddressObject = await request.post(`${baseAPI}/utils/bitcoin/send-tx`, {
            txHex,
            testnet: isTestnet,
        });
        const txId = responseAddressObject?.data;
        return txId;
    };
    const createNFTPsbt = async (data: any): Promise<any> => {
        const { extendedUtxo, nftExtendedUtxo, receiverAddress, feeRate, changeAddress } = data;
        const responseAddressObject: ResponseAddressObject = await request.post(
            `${baseAPI}/utils/bitcoin/create-nft-psbt`,
            {
                ...data,
                testnet: isTestnet,
            },
        );
        const unsignedTx = responseAddressObject;
        return unsignedTx;
    };
    const getInscriptionsAndPaymentsUtxo = async (account: any, location: string): Promise<any> => {
        let _account: any = account;
        const { addressType: bitcoinAddressType } = await getAddressObjectByAddress(_account.address);

        _account["addressType"] =
            (account?.addressType || bitcoinAddressType) === "p2sh"
                ? "p2sh-p2wpkh"
                : account?.addressType || bitcoinAddressType;

        const paymentUtxo: any = await request.get(`${baseAPI}/proxy/get-utxo/${account?.address}`);

        const txIdx = location?.split(":")[0];
        const outputIdx = +location?.split(":")[1];

        const responseTx: any = await request.get(`/api/mempool?txIdx=${txIdx}`);

        const tempInscriptionsUtxo = [
            {
                signerInfo: {
                    address: _account.address,
                    addressType: _account.addressType,
                    publicKey: _account.publicKey,
                },
                value: responseTx?.vout[outputIdx]?.value,
                hash: txIdx,
                index: +outputIdx,
            },
        ];
        const tempPaymentsUtxo = (paymentUtxo?.data || []).map((utxo: any) => {
            const _utxo = {
                signerInfo: {
                    address: _account.address,
                    addressType: _account.addressType,
                    publicKey: _account.publicKey,
                },
                value: utxo.value,
                hash: utxo.txId,
                index: +utxo.index,
            };
            return _utxo;
        });

        if (!tempPaymentsUtxo || tempPaymentsUtxo.length === 0) {
            throw new Error("no Payment Utxo found");
        }
        if (!tempInscriptionsUtxo || tempInscriptionsUtxo.length === 0) {
            throw new Error("no Inscription Utxo found");
        }
        return { tempInscriptionsUtxo, tempPaymentsUtxo };
    };
    const getFeeRate = async () => {
        let feeRate = 40;
        try {
            const feeRateResponse = await fetch(`${mempoolAPI}/v1/fees/recommended`).then((res) => res.json());
            feeRate = +parseCustomTokenValue(Math.ceil(feeRateResponse?.halfHourFee || feeRate) * 500, 8);
        } catch (e) {}
        return feeRate;
    };
    return {
        getFeeRate,

        getInscriptionsAndPaymentsUtxo,

        sendBitcoinTx,
        createNFTPsbt,

        getAddressObjectByAddress,
        getLockersByType,
    };
};

export default useBitcoin;
