/* eslint-disable @typescript-eslint/no-unused-vars */
import Button from "ui-components/Button";
import { useConnectWallet } from "@web3-onboard/react";
import { useGlobalDispatch, useGlobalState } from "states/globalContext";
import { toSliceAddress } from "utils/convert";
import Image from "ui-components/Image";
import classNames from "classnames";
import { useBitcoinWallet } from "states/BitcoinWalletContextProvider";
import { useTonAddress } from "@tonconnect/ui-react";

export default function ConnectWallet({ type = "button", className }: { type: "button" | "icon"; className?: string }) {
    const [{ wallet }, connect, disconnect] = useConnectWallet();
    const account = wallet?.accounts[0]?.address?.toLowerCase();
    const accountTON = useTonAddress(true);

    const bitcoinWallet = useBitcoinWallet();
    const bitcoinWalletAddress = bitcoinWallet ? bitcoinWallet?.wallet?.address : null;
    const btcAddressSlice =
        bitcoinWalletAddress && bitcoinWalletAddress?.length ? `${toSliceAddress(bitcoinWalletAddress)}` : null;

    const globalDispatch = useGlobalDispatch();

    const { walletOptions } = useGlobalState();

    const addressSlice = account && account?.length ? `${toSliceAddress(account)}` : null;
    const addressSliceTON = accountTON && accountTON?.length ? `${toSliceAddress(accountTON)}` : null;

    const isNeedAllWallet = walletOptions.value?.includes("bitcoin-evm-ton");

    const handleWallets = (walletType: any) => {
        globalDispatch({
            type: "setWalletOptions",
            value: { show: true, value: isNeedAllWallet ? "bitcoin-evm-ton" : walletType },
        });
    };

    const showMulti = [!!account, !!accountTON, !!bitcoinWalletAddress].filter(Boolean).length >= 2;

    return (
        <div
            className={classNames(
                "flex items-center text-right  cursor-pointer justify-end -mt-8 md:mt-0 mr-3 md:mr-0",
                type !== "button" ? "w-full" : "",
                "md:min-w-[150px]",
                className,
            )}
        >
            <div className={`flex gap-1  flex-col md:flex-row justify-center w-full ${type !== "button" ? "" : ""} `}>
                {(walletOptions?.value === "evm" ||
                    (isNeedAllWallet && account && !(accountTON || bitcoinWalletAddress))) && (
                    <div className={classNames("")}>
                        <Button
                            onClick={() => {
                                handleWallets("evm");
                            }}
                            className="w-full min-w-[140px] md:min-w-[160px] bg-primary hover:bg-ground-lighter text-secondary hover:text-white"
                        >
                            {account ? (
                                <span className="flex text-sm md:text-base gap-2 items-center w-full justify-evenly">
                                    <Image src="/images/metamask.svg" width="21" height="21" />
                                    {addressSlice}
                                </span>
                            ) : !isNeedAllWallet ? (
                                "Connect  wallet"
                            ) : (
                                "Connect EVM wallet"
                            )}
                        </Button>
                    </div>
                )}
                {(walletOptions?.value === "ton" ||
                    (isNeedAllWallet && accountTON && !(account || bitcoinWalletAddress))) && (
                    <div className={classNames("")}>
                        <Button
                            onClick={() => {
                                handleWallets("ton");
                            }}
                            className="w-full min-w-[140px] md:min-w-[160px] bg-primary hover:bg-ground-lighter text-secondary hover:text-white"
                        >
                            {accountTON ? (
                                <span className="flex text-sm md:text-base gap-2 items-center w-full justify-evenly">
                                    <Image src="/images/ton.png" width="21" height="21" />
                                    {addressSliceTON}
                                </span>
                            ) : !isNeedAllWallet ? (
                                "Connect  wallet"
                            ) : (
                                "Connect TON wallet"
                            )}
                        </Button>
                    </div>
                )}
                {(walletOptions?.value === "bitcoin" ||
                    (isNeedAllWallet && bitcoinWalletAddress && !(account || accountTON))) && (
                    <div className={classNames("")}>
                        <Button
                            onClick={() => {
                                handleWallets("bitcoin");
                            }}
                            className="w-full min-w-[140px] md:min-w-[160px] bg-primary hover:bg-ground-lighter text-secondary hover:text-white"
                        >
                            {bitcoinWalletAddress ? (
                                <span className="flex gap-2 items-center w-full justify-evenly">
                                    <Image src={`/images/${bitcoinWallet?.wallet?.icon}.svg`} width="23" height="23" />
                                    {btcAddressSlice}
                                </span>
                            ) : (
                                <span className="flex items-center">
                                    {!isNeedAllWallet ? "Connect  wallet" : "Connect BTC wallet"}
                                </span>
                            )}
                        </Button>
                    </div>
                )}
                {isNeedAllWallet && !account && !accountTON && !bitcoinWalletAddress && (
                    <div className={classNames("")}>
                        <Button
                            onClick={() => {
                                handleWallets("bitcoin-evm-ton");
                            }}
                            className="w-full min-w-[140px] md:min-w-[160px] bg-primary hover:bg-ground-lighter text-secondary hover:text-white"
                        >
                            <span className="flex items-center">Connect wallet</span>
                        </Button>
                    </div>
                )}
                {isNeedAllWallet && showMulti && (
                    <div className={classNames("")}>
                        <Button
                            onClick={() => {
                                handleWallets("bitcoin-evm-ton");
                            }}
                            className="w-full min-w-[140px] md:min-w-[160px] bg-primary hover:bg-ground-lighter text-secondary hover:text-white"
                        >
                            <span className="flex text-sm md:text-base gap-2 items-center w-full justify-evenly">
                                {account && (
                                    <Image src="/images/metamask.svg" className={"-ml-1"} width="21" height="21" />
                                )}
                                {accountTON && (
                                    <Image src="/images/ton.png" className={"-ml-1"} width="21" height="21" />
                                )}
                                {bitcoinWalletAddress && (
                                    <Image
                                        src={`/images/${bitcoinWallet?.wallet?.icon}.svg`}
                                        className={"-ml-1"}
                                        width="23"
                                        height="23"
                                    />
                                )}
                                See wallets
                            </span>
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}
