import "./styles/globals.css";

import ProviderWeb3Wallet from "components/ProviderWeb3Wallet";
import QueryClientContainer from "QueryClientContainer";
import { ThemeProvider } from "states/themeContext";
import { GlobalProvider } from "states/globalContext";
import { SignerProvider } from "states/signerContext";
import { NotificationProvider } from "states/notificationContext";
import { ToastProvider } from "states/toastContext";
import { RoutesCal } from "./routes";
import { Notification } from "./components/Notification";
import { BitcoinWalletProvider } from "states/BitcoinWalletContextProvider";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { isTestnet } from "utils/configs";

const manifestUrl = `https://statics.teleportdao.xyz/testnet/other/test-tonconnect-manifest.json`;

export default function App() {
    return (
        <>
            <GlobalProvider>
                <SignerProvider>
                    <TonConnectUIProvider manifestUrl={manifestUrl}>
                        <ProviderWeb3Wallet>
                            <QueryClientContainer>
                                <BitcoinWalletProvider>
                                    <ThemeProvider>
                                        <NotificationProvider>
                                            <ToastProvider>
                                                <RoutesCal />
                                                <Notification />
                                            </ToastProvider>
                                        </NotificationProvider>
                                    </ThemeProvider>
                                </BitcoinWalletProvider>
                            </QueryClientContainer>
                        </ProviderWeb3Wallet>
                    </TonConnectUIProvider>
                </SignerProvider>
                <div className="fixed hidden md:flex right-[80px] bottom-[20px] px-3 py-1 rounded-lg bg-[#3886ff] text-white">
                    Need help?
                </div>
            </GlobalProvider>
        </>
    );
}
