/* eslint-disable @typescript-eslint/no-unused-vars */
import { formatUnits, parseUnits } from "@ethersproject/units";
import { BigNumber, BigNumberish, ethers } from "ethers";
import { BigNumber as BigNumberJS } from "bignumber.js";
import { capitalize } from "./functions";
import { convertExponentialToDecimal } from "./number";
import { contractAddress, lowBurnConfirm, normalBurnConfirm } from "./configs";
import { NetworkTypeDTO } from "models/DTO";

const expToken = BigNumber.from(10).pow(18);
const expPrice = BigNumber.from(10).pow(8);

import Web3 from "web3";
import { isCrossNetwork } from "./useContract";

function removeExtraZeros(n: any) {
    // Convert number to string
    if (+n === 0) {
        return "0";
    }

    let numStr = n.toString();

    // Check if the number is in scientific notation
    if (numStr.indexOf("e") !== -1) {
        // Convert the number to a non-exponential form
        let [base, exponent] = numStr.split("e");
        let decimalPlaces = Math.max(parseInt(exponent, 10), 0); // Ensure the decimalPlaces is not negative
        numStr = n.toFixed(decimalPlaces + 1);
    }

    // Remove unnecessary trailing zeros after decimal point
    numStr = numStr.replace(/(\.\d*[1-9])0+$/, "$1");

    // Remove trailing decimal point if it's the last character
    numStr = numStr.replace(/\.$/, "");

    return numStr;
}

function toTrunc(value: any, n: any) {
    if (Math.abs(value) < 1.0) {
        let e = parseInt(value.toString().split("e-")[1]);
        if (e) {
            value *= Math.pow(10, e - 1);
            value = "0." + new Array(e).join("0") + value.toString().substring(2);
        }
    } else {
        let e = parseInt(value.toString().split("+")[1]);
        if (e > 20) {
            e -= 20;
            value /= Math.pow(10, e);
            value += new Array(e + 1).join("0");
        }
    }

    const x = (value.toString() + ".0").split(".");

    return n === 0 ? x[0] : (x[0] + "." + x[1].substr(0, n)).toString();
}
const setDigitWithFractionMax = (value: any): any => {
    return setDigitWithFraction(value, 8);
};
function isNaN(x: any) {
    // Coerce into number
    x = Number(x);
    // if x is NaN, NaN != NaN is true, otherwise it's false
    return x != x;
}
const setDigitWithFraction = (value: any, maximumFraction: any = 8): any => {
    if (isNaN(value)) {
        return 0;
    }
    return removeExtraZeros(toTrunc(value, maximumFraction));
};
const setDigitWithFractionCondition = (value: any, maximumFraction: any = 8, showFullValue = false): any => {
    return removeExtraZeros(toTrunc(value, +value > 1 ? (showFullValue ? maximumFraction : 0) : maximumFraction));
};
const toSliceURL = (url: string): string => {
    return `${url.slice(0, 20)}...${url.slice(url?.length - 3, url?.length)}`;
};
const parseValue = (amount: any): number => {
    return BigNumber.from(amount?._hex).toNumber();
    // if (amount === "0x00" || amount === 0) {
    //     return 0;
    // } else {
    //     return BigNumber.from(amount?._hex).toNumber();
    // }
};
const toValue = (amount: string): BigNumberish => {
    const dotIndex = amount?.toString()?.indexOf(".");
    const realDecimal = dotIndex > 0 ? amount?.toString()?.substring(dotIndex + 1).length : 0;

    const amountTemp = ~~(Number(amount) * Math.pow(10, realDecimal));
    return BigNumber.from(10)
        .pow(1 - realDecimal)
        .mul(amountTemp);
};

const toTokenValue = (amount: any): any => {
    const dotIndex = amount?.toString()?.indexOf(".");
    const realDecimal = dotIndex > 0 ? amount?.toString()?.substring(dotIndex + 1).length : 0;

    const amountTemp = ~~(Number(amount) * Math.pow(10, realDecimal));
    return BigNumber.from(10)
        .pow(18 - realDecimal)
        .mul(amountTemp);
};

const parseBTCTokenValue = (amount: any, maximumFraction: any = 8): any => {
    try {
        if (typeof amount === "string" || amount instanceof String) {
            return parseCustomTokenValue(amount, 8);
        } else {
            return parseCustomTokenValue(amount, 8);
        }
    } catch (e) {
        return 909090;
    }
};
const toBTCTokenValue = (amount: string | number): BigNumberish | BigNumber => {
    try {
        // const dotIndex = amount?.toString()?.indexOf(".");
        // const realDecimal = dotIndex > 0 ? amount?.toString()?.substring(dotIndex + 1).length : 0;

        // const amountTemp = +(Number(amount) * Math.pow(10, realDecimal));
        // return BigNumber.from(10)
        //     .pow(8 - realDecimal)
        //     .mul(amountTemp);
        return parseUnits(amount?.toString(), 8);
    } catch (e) {
        return BigNumber.from(10).pow(8).mul(0);
    }
};
const toCustomTokenValue = (amount: string | number, decimal: number): BigNumberish | BigNumber => {
    try {
        const newAmount = "" + (+amount).toFixed(decimal) + "";
        // const dotIndex = amount?.toString()?.indexOf(".");
        // const realDecimal = dotIndex > 0 ? amount?.toString()?.substring(dotIndex + 1).length : 0;

        // const amountTemp = +(Number(amount) * Math.pow(10, realDecimal));
        // return BigNumber.from(10)
        //     .pow(8 - realDecimal)
        //     .mul(amountTemp);
        return parseUnits(newAmount, decimal);
    } catch (e) {
        return BigNumber.from(10).pow(decimal).mul(0);
    }
};
const toCustomTokenValueBig = (amount: string | number, decimal: number): any => {
    try {
        return new BigNumberJS(amount).times(10 ** decimal);
    } catch (e) {
        return new BigNumberJS(0).times(10 ** decimal);
    }
};

const parseCustomTokenValue = (amount: any, decimal: number, maximumFraction: any = 8): any => {
    try {
        if (typeof amount === "string" || amount instanceof String) {
            const value = truncateNumber(
                convertExponentialToDecimal(Number(amount) / +`1e${decimal}`, decimal),
                decimal,
            );
            return setDigitWithFraction(value, maximumFraction);
        } else {
            const value = truncateNumber(
                convertExponentialToDecimal(Number(amount) / +`1e${decimal}`, decimal),
                decimal,
            );
            return setDigitWithFraction(value, maximumFraction);
        }
    } catch (e) {
        return 909090;
    }
};
const toBTC = (amount: BigNumberish): number => {
    return parseFloat(formatUnits(amount, 6)) / 100;
};

const untilDays = (to_day: Date = new Date()): number => {
    // One day Time in ms (milliseconds)
    const one_day = 1000 * 60 * 60 * 24;

    // To set present_dates to two variables
    const present_date = new Date();

    // To Calculate next year's to_day if passed already.
    if (present_date.getMonth() === 11 && present_date.getDate() > 25) to_day.setFullYear(to_day.getFullYear() + 1);

    // To Calculate the result in milliseconds and then converting into days
    const Result = Math.round(to_day.getTime() - present_date.getTime()) / one_day;

    // To remove the decimals from the (Result) resulting days value
    const Final_Result = Result.toFixed(0);
    return Number(Final_Result);
};

const untilMinutes = (to_day: Date = new Date()): number => {
    // To set present_dates to two variables
    const present_date = new Date();
    const minutes = parseInt("" + ((Math.abs(to_day.getTime() - present_date.getTime()) / (1000 * 60)) % 60));

    return to_day.getTime() >= present_date.getTime() ? minutes : 0;
};

const toSliceAddress = (address: any): any => {
    return `${address?.substr(0, 8)}...${address?.substr(address?.length - 4, address?.length - 1)}`;
};

const truncateNumber = (num: any, digits: number) => {
    var re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)"),
        m = num.toString().match(re);
    return m ? parseFloat(m[1]) : num.valueOf();
};

const crossTokenToPolygonTokenMapper = (
    token: any,
    crossNetwork: NetworkTypeDTO,
    type: "wrap" | "unwrap" = "unwrap",
) => {
    if (token.address === "-") {
        return {
            address: contractAddress?.[NetworkTypeDTO.polygon].teleBTCAddress?.toLowerCase(),
            symbol: "BTC",
            decimal: 8,
            icon: "BTC",
            symbolPrice: "BTC",
            type: "btc",
        };
    } else if (token.address === contractAddress?.[crossNetwork].WNATIVEAddress?.toLowerCase()) {
        const isNativeToken = !token?.symbol?.startsWith("W");
        return {
            address: contractAddress?.[NetworkTypeDTO.polygon]?.WETHAddress?.toLowerCase(),
            symbol: isNativeToken ? "ETH" : "WETH",
            icon: "ethereum",
            decimal: 18,
            symbolPrice: isNativeToken ? "ETH" : "WETH",
            type: "evm",
        };
    } else if (token.address === contractAddress?.[crossNetwork].USDCAddress?.toLowerCase()) {
        return {
            address: contractAddress?.[NetworkTypeDTO.polygon]?.USDCAddress?.toLowerCase(),
            symbol: "USDC",
            icon: "USDC",
            decimal: 6,
            symbolPrice: "USDC",
            type: "evm",
        };
    } else if (token.address === contractAddress?.[crossNetwork].USDTAddress?.toLowerCase()) {
        return {
            address: contractAddress?.[NetworkTypeDTO.polygon]?.USDTAddress?.toLowerCase(),
            symbol: "USDT",
            icon: "tether",
            decimal: 6,
            symbolPrice: "USDT",
            type: "evm",
        };
    } else if (token.address === contractAddress?.[crossNetwork].MATICAddress?.toLowerCase()) {
        return {
            address: contractAddress?.[NetworkTypeDTO.polygon]?.WNATIVEAddress?.toLowerCase(),
            symbol: "MATIC",
            icon: "polygon",
            decimal: 18,
            symbolPrice: "MATIC",
        };
    } else if (token.address === contractAddress?.[crossNetwork].WBTCAddress?.toLowerCase()) {
        return {
            address: contractAddress?.[NetworkTypeDTO.polygon]?.WBTCAddress?.toLowerCase(),
            symbol: "WBTC",
            icon: "wbtc",
            decimal: 8,
            symbolPrice: "BTC",
        };
    }
    return token;
};

const getMessageForSwapAndUnwrap = (data: any) => {
    const {
        uniqueCounter,
        sourceChainId,
        user,
        exchangeConnector,
        minAmountOut,
        path,
        userScript,
        scriptType,
        lockerLockingScript,
        thirdParty,
        isFixedToken,
    } = data;
    const abiUtils = new Web3().eth.abi;
    const message: any = abiUtils.encodeParameters(
        [
            "string",
            "uint",
            "uint",
            "address",
            "address",
            "uint",
            "bool",
            "address[]",
            {
                UserAndLockerScript: {
                    userScript: "bytes",
                    scriptType: "uint",
                    lockerLockingScript: "bytes",
                },
            },
            "uint",
        ],
        [
            "swapAndUnwrap",
            +uniqueCounter,
            +sourceChainId,
            user,
            exchangeConnector,
            +minAmountOut,
            isFixedToken,
            path,
            { userScript, scriptType: +scriptType, lockerLockingScript },
            +thirdParty,
        ],
    );
    //TODO_web3
    return message;
};

const userActionMessageType: any = {
    withdraw: [
        {
            name: "chainId",
            type: "uint256",
        },
        {
            name: "token",
            type: "address",
        },
        {
            name: "amount",
            type: "uint256",
        },
        {
            name: "relayerFeePercentage",
            type: "int64",
        },
    ],
    retry: [
        {
            name: "chainId",
            type: "uint256",
        },
        {
            name: "token",
            type: "address",
        },
        {
            name: "amount",
            type: "uint256",
        },
        {
            name: "exchangeConnector",
            type: "address",
        },
        {
            name: "outputAmount",
            type: "uint256",
        },
        {
            name: "userScript",
            type: "bytes",
        },
        {
            name: "scriptType",
            type: "uint8",
        },
        {
            name: "lockerLockingScript",
            type: "bytes",
        },
        {
            name: "path",
            type: "address[]",
        },
    ],
    swapAndUnwrap: [
        {
            name: "functionType",
            type: "string",
        },
        {
            name: "uniqueCounter",
            type: "uint256",
        },
        {
            name: "sourceChainId",
            type: "uint256",
        },
        {
            name: "user",
            type: "address",
        },
        {
            name: "exchangeConnector",
            type: "address",
        },
        {
            name: "amount",
            type: "uint256",
        },
        {
            name: "isFixedToken",
            type: "bool",
        },
        {
            name: "path",
            type: "address[]",
        },
        {
            components: [
                {
                    name: "userScript",
                    type: "bytes",
                },
                {
                    name: "scriptType",
                    type: "uint8",
                },
                {
                    name: "lockerLockingScript",
                    type: "bytes",
                },
            ],
            name: "scripts",
            type: "tuple",
        },
        {
            name: "thirdParty",
            type: "uint256",
        },
    ],
};

function decodeCrossChainMessage(data: string, type: any) {
    const abiUtils = new Web3().eth.abi;
    let m = abiUtils.decodeParameters(userActionMessageType?.[type], data);
    return deleteEventNumbers(m);
}
function deleteEventNumbers(obj: any) {
    for (let key in obj) {
        if (Array.isArray(obj[key])) {
            obj[key] = Object.assign({}, obj[key]); // {0:"a", 1:"b", 2:"c"}
        }

        if (!Number.isNaN(Number(key))) {
            delete obj[key];
            continue;
        }
        if (typeof obj[key] == "object") {
            deleteEventNumbers(obj[key]);
        }
    }
    return obj;
}
const calcBurnConfirmation = (amount: any, network: NetworkTypeDTO, type: "BRC-20" | "RUNE" | "BTC" = "BTC") => {
    if (+amount < 0.035 || network === NetworkTypeDTO.binance || type === "RUNE") {
        return lowBurnConfirm + (isCrossNetwork(network) ? 1 : 0) + (type === "RUNE" ? 1 : 0);
    } else {
        return normalBurnConfirm;
    }
};
export {
    calcBurnConfirmation,
    decodeCrossChainMessage,
    getMessageForSwapAndUnwrap,
    crossTokenToPolygonTokenMapper,
    truncateNumber,
    untilDays,
    untilMinutes,
    toSliceAddress,
    toValue,
    parseValue,
    toTokenValue,
    parseBTCTokenValue,
    toBTCTokenValue,
    parseCustomTokenValue,
    toCustomTokenValue,
    toBTC,
    expToken,
    expPrice,
    setDigitWithFraction,
    toSliceURL,
    setDigitWithFractionMax,
    setDigitWithFractionCondition,
    toTrunc,
    removeExtraZeros,
    toCustomTokenValueBig,
};
