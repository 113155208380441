import { useConnectWallet } from "@web3-onboard/react";
import { useEffect, useMemo, useState } from "react";
import useWallet from "./useWallet";
import { ethers } from "ethers";
import { JsonRpcProviderOption, firstNetwork } from "./configs";
import { useSignerDispatch } from "states/signerContext";
import { NetworkTypeDTO } from "models/DTO";

const useSigner = () => {
    const signerDispatch = useSignerDispatch();
    const [{ wallet }] = useConnectWallet();
    const [network, setNetwork] = useState(firstNetwork);

    const { checkIsValidNetwork } = useWallet();

    const isValidNetwork = checkIsValidNetwork(network);
    useEffect(() => {
        signerDispatch({ type: "setOptionJsonRpcProvider", value: JsonRpcProviderOption });
    }, []);

    useMemo(() => {
        if (wallet && isValidNetwork) {
            const _provider = new ethers.providers.Web3Provider(wallet.provider, "any");
            if (_provider?.["getSigner"]) {
                const provider = _provider.getSigner();

                signerDispatch({ type: "setProvider", value: provider });
            } else {
                const provider = JsonRpcProviderOption?.[network];
                signerDispatch({ type: "setProvider", value: provider });
            }
        } else {
            const provider = JsonRpcProviderOption?.[network];
            signerDispatch({ type: "setProvider", value: provider });
        }
    }, [wallet, isValidNetwork, network]);

    const changeProvider = (newNetwork: NetworkTypeDTO): void => {
        network !== newNetwork && setNetwork(newNetwork);
    };
    return {
        changeProvider,
    };
};

export default useSigner;
