/* eslint-disable @typescript-eslint/no-unused-vars */
import Image from "ui-components/Image";
import { useNavigate, useLocation } from "react-router-dom";
import Tabs from "ui-components/Tabs";
import { useMediaQuery } from "utils/useMediaQuery";
import { useScreenDetector } from "utils/useScreenDetector";
import { isTestnet } from "utils/configs";

interface SidebarProps {
    children: any;
}
const Sidebar = ({ children }: SidebarProps) => {
    const { isMobile } = useScreenDetector();
    const links: {
        index: any;
        key: string;
        value: any;
        content: any;
    }[] = isTestnet
        ? [
              {
                  index: 0,
                  key: "/bridge",
                  value: <span className="flex  gap-1 items-center">Bridge</span>,
                  content: children,
              },
              { index: 1, key: "/transaction-history", value: "My history", content: children },
              { index: 2, key: "/network-stats", value: "Network stats", content: children },
          ]
        : [
              {
                  index: 0,
                  key: "/bridge",
                  value: <span className="flex  gap-1 items-center">Bridge</span>,
                  content: children,
              },
              {
                  index: 1,
                  key: "/staking",
                  value: (
                      <span className="flex  gap-1 items-center">
                          Staking
                          {/* <span className="bg-core px-2  py-1  rounded-lg card-shine-effect"> New: TST!</span> */}
                      </span>
                  ),
                  content: children,
              },
              // {
              //     index: 2,
              //     key: "/farming",
              //     value: (
              //         <span>
              //             Farming
              //             {/* <span className="bg-core px-1 py-[2px]  rounded-lg">Boosted TST</span> */}
              //         </span>
              //     ),
              //     content: children,
              // },

              // {
              //     index: 3,
              //     key: "/referral-stats",
              //     value: <span>Referral</span>,
              //     content: children,
              // },
              { index: 2, key: "/transaction-history", value: "My history", content: children },
              { index: 3, key: "/network-stats", value: "Network stats", content: children },
          ];

    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location?.pathname;

    const activeHomeIndex = (pathname === "/" ? 0 : links.find((i) => i.key.includes(pathname))?.index) || 0;

    const handleChange = (index: number) => {
        navigate(`${links[index].key}`);
    };
    const matches = useMediaQuery("(min-width: 768px)");
    return (
        <div className="flex flex-col  md:max-w-[1000px] w-full  relative ">
            <Tabs
                type={matches ? "outline" : "inline"}
                bg={matches ? "bg-ground-dark" : "bg-ground"}
                selectedIndex={activeHomeIndex}
                items={links}
                showNav={false}
                onChange={handleChange}
            />
            {/* <div className="w-full flex justify-end">
                <a
                    href={"https://teleportdao.xyz/"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                    className="  h-[40px] w-fit pt-2 px-4 md:px-2 text-primary text-base flex items-center justify-end mb-5 md:mb-1 "
                >
                    Powered by
                    <Image
                        className=" mx-1"
                        title="Teleport-DAO"
                        src={"/images/teleport.png"}
                        width="24"
                        height="24"
                        alt="Teleport-DAO"
                    />
                    TeleportDAO
                </a>
            </div> */}
        </div>
    );
};
export default Sidebar;
