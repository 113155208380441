import { ResponseCurrencyObject } from "interfaces/DTO";
import { NetworkTypeDTO } from "models/DTO";
import { baseAPI } from "utils/configs";
import useRequest from "utils/useRequest";

const usePrice = () => {
    const request = useRequest();

    const getTokenPrice = async (_currency: string): Promise<string | number> => {
        const currency = _currency
            ?.replace("WBTC", "BTC")
            ?.replace("BTCB", "BTC")
            ?.replace("WBNB", "BNB")
            .replace("WMATIC", "MATIC")
            .replace("WETH", "ETH")
            ?.toUpperCase();
        const priceObject: ResponseCurrencyObject = await request.get<ResponseCurrencyObject>(
            `${baseAPI}/general/get-coins`,
        );
        const findToken = priceObject?.data?.find((i: any) => i.slug === currency);
        try {
            if (findToken) {
                return findToken?.priceUSD || 0;
            } else {
                return 1;
            }
        } catch (error) {
            return 0;
        }
    };
    const getAllTokenPrice = async (): Promise<any> => {
        const priceObject: ResponseCurrencyObject = await request.get<ResponseCurrencyObject>(
            `${baseAPI}/general/get-coins`,
        );
        return priceObject?.data;
    };
    const getNativeTokenBTCRate = async (): Promise<any> => {
        const priceObject: ResponseCurrencyObject = await request.get<ResponseCurrencyObject>(
            `${baseAPI}/general/get-coins`,
        );
        const btcToken: any = priceObject?.data?.find((i: any) => i.slug === "BTC");
        const maticToken: any = priceObject?.data?.find((i: any) => i.slug === "MATIC");
        const bnbToken: any = priceObject?.data?.find((i: any) => i.slug === "BNB");
        const ethToken: any = priceObject?.data?.find((i: any) => i.slug === "ETH");
        const tonToken: any = priceObject?.data?.find((i: any) => i.slug === "TON");
        try {
            return {
                [NetworkTypeDTO.binance]: +bnbToken?.priceUSD / +btcToken?.priceUSD,
                [NetworkTypeDTO.polygon]: +maticToken?.priceUSD / +btcToken?.priceUSD,
                [NetworkTypeDTO.bsquared]: +btcToken?.priceUSD / +btcToken?.priceUSD,
                [NetworkTypeDTO.bob]: +ethToken?.priceUSD / +btcToken?.priceUSD,
                [NetworkTypeDTO.ton]: +tonToken?.priceUSD / +btcToken?.priceUSD,
            };
        } catch (error) {
            return {
                [NetworkTypeDTO.binance]: 0,
                [NetworkTypeDTO.polygon]: 0,
                [NetworkTypeDTO.bsquared]: 0,
                [NetworkTypeDTO.bob]: 0,
                [NetworkTypeDTO.ton]: 0,
            };
        }
    };

    const getTokenPriceDetails = (data: any, _currency: string) => {
        const currency = _currency
            ?.toLowerCase()
            ?.replace("telebtc", "btc")
            ?.replace("wbtc", "btc")
            ?.replace("btcb", "btc")
            ?.replace("wbnb", "bnb")
            .replace("wmatic", "matic")
            .replace("weth", "eth")
            ?.toUpperCase();

        const findToken = (data || [])?.find((i: any) => i.slug === currency);
        try {
            if (findToken) {
                return findToken?.priceUSD || 0;
            } else {
                return 1;
            }
        } catch (error) {
            return 0;
        }
    };
    return {
        getTokenPriceDetails,
        getAllTokenPrice,
        getNativeTokenBTCRate,
        getTokenPrice,
    };
};

export default usePrice;
